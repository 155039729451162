export const UTM_SOURCE = process.env.NEXT_PUBLIC_APP_URL.replace('http://', '').replace(
    'https://',
    '',
);

const URL = 'https://a-propos.partager.io';

export const links = {
    about: `${URL}/qui-sommes-nous?utm_source=${UTM_SOURCE}&utm_medium=web`,
    jobs: `${URL}/nous-recrutons?utm_source=${UTM_SOURCE}&utm_medium=web`,
    contact: `${URL}/contact?utm_source=${UTM_SOURCE}&utm_medium=web`,
    faq: `${URL}/faq?utm_source=${UTM_SOURCE}&utm_medium=web`,
    features: `${URL}/fonctions?utm_source=${UTM_SOURCE}&utm_medium=web`,
    prices: `${URL}/tarifs?utm_source=${UTM_SOURCE}&utm_medium=web`,
    partagerVSsubstack: `${URL}/partager-vs-substack?utm_source=${UTM_SOURCE}&utm_medium=web`,
    partagerVSkessel: `${URL}/partager-vs-kessel?utm_source=${UTM_SOURCE}&utm_medium=web`,
    partagerVSmailchimpVSsendinblue: `${URL}/partager-vs-mailchimp-vs-sendinblue?utm_source=${UTM_SOURCE}&utm_medium=web`,
    mentionsLegal: `${URL}/mentions-legales?utm_source=${UTM_SOURCE}&utm_medium=web`,
    privacy: `${URL}/politique-de-confidentialite?utm_source=${UTM_SOURCE}&utm_medium=web`,
    cgs: `${URL}/cgs?utm_source=${UTM_SOURCE}&utm_medium=web`,
    cgu: `${URL}/cgu?utm_source=${UTM_SOURCE}&utm_medium=web`,
    cookiesPolicy: `${URL}/politique-cookies?utm_source=${UTM_SOURCE}&utm_medium=web`,
};
