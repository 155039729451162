import QueryString from 'qs';

import type { IComment } from '.';
import { getAxiosAPI, postAxiosAPI } from '../../lib/request';
import type { IStrapiResponse } from '../../shared/interfaces/strapi-response';

const urlPlural = '/comments';

export async function getComments(
    api: string,
    entityId: number,
    query: Record<string, unknown>,
): Promise<IStrapiResponse<IComment[]>> {
    const queryString = QueryString.stringify(query);
    const response = await getAxiosAPI<IStrapiResponse<IComment[]>>(
        `${urlPlural}/api::${api}:${entityId}/flat?${queryString}`,
    );
    return response.data;
}

export async function postComment(
    api: string,
    entityId: number,
    data: FormData | unknown,
    userToken: string,
): Promise<IComment> {
    const response = await postAxiosAPI<IComment>(
        `${urlPlural}/api::${api}:${entityId}`,
        data,
        userToken,
    );
    return response.data;
}
