import IconChatFill from 'bootstrap-icons/icons/chat-fill.svg';
import IconChat from 'bootstrap-icons/icons/chat.svg';

export interface CommentCounterProps {
    count: number;
    commented?: boolean;
    className?: string;
}

export default function CommentCounter({
    count,
    commented = false,
    className = '',
}: CommentCounterProps): JSX.Element {
    let icon = <IconChat className="mr-1" />;
    const color = '';

    if (commented) {
        icon = <IconChatFill className="mr-1" />;
    }
    return (
        <div className={`flex items-center text-xs dark:text-white ${color} ${className}`}>
            {icon}
            {count || ''}
        </div>
    );
}
