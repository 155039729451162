import QueryString from 'qs';

import { deleteAxiosAPI, getAxiosAPI, postAxiosAPI } from '../../lib/request';
import type { IStrapiResponse } from '../../shared/interfaces/strapi-response';
import type { IAction } from './action.interface';

const urlPlural = '/actions';

export async function getActions<T = IAction>(
    queryObject?: Record<string, unknown>,
): Promise<IStrapiResponse<T[]>> {
    const queryString = QueryString.stringify(queryObject);
    const response = await getAxiosAPI<IStrapiResponse<T[]>>(`${urlPlural}?${queryString}`);
    return response.data;
}

export async function createAction<T = IAction>(
    data: FormData | unknown,
    userToken: string,
): Promise<IStrapiResponse<T>> {
    const response = await postAxiosAPI(`${urlPlural}`, data, userToken);
    return response.data as IStrapiResponse<T>;
}

export async function deleteAction<T = IAction>(
    actionId: IAction['id'],
): Promise<IStrapiResponse<T>> {
    const response = await deleteAxiosAPI(`${urlPlural}/${actionId}`);
    return response.data;
}
