import type { JSONContent } from '@tiptap/core';

import { PaywallName } from '../../components/Tiptap/Extensions/PaywallWrapper/ExtensionPaywallWrapper';
import type { IAction } from '../../features/action';
import type { IComment } from '../../features/comment';
import { getComments } from '../../features/comment';
import type { IOfferType } from '../../features/offerType';
import type { IPost } from '../../features/post';
import { getPost, getPosts } from '../../features/post';
import type { SchemaOrgBlogPostingDefinition } from '../../features/post/schema';
import { getBlogPostingSchema } from '../../features/post/schema';
import type { IPublication } from '../../features/publication';
import type { ISubscriberList } from '../../features/subscriberList';
import type { IUser } from '../../features/user';
import type IMedia from '../../shared/interfaces/media.interface';
import { ErrorMessages } from '../errorMessages';
import type { PageRedirect } from '../interfaces/page';
import { goToHomePage } from '../page-helper';

// TODO: Populating fields seems not to work with comments, investigate why
export function commentsQuery(page: number, pageSize: number): Record<string, unknown> {
    return {
        filters: {
            blocked: { $not: true },
        },
        sort: 'createdAt:desc',
        pagination: {
            page,
            pageSize,
            withCount: true,
        },
    };
}

export interface PickedPost
    extends Pick<
        IPost,
        | 'id'
        | 'title'
        | 'subtitle'
        | 'seoTitle'
        | 'text'
        | 'isPaid'
        | 'seoSubtitle'
        | 'publishedAt'
        | 'updatedAt'
        | 'createdAt'
        | 'hideCover'
        | 'readingTime'
        | 'wordsCount'
    > {
    user: Pick<IUser, 'id' | 'pseudoName' | 'slug' | 'biography'> & {
        avatar: Pick<IMedia, 'url'> | null;
    };
    publication: Pick<
        IPublication,
        'id' | 'title' | 'description' | 'thematic' | 'slug' | 'createdAt' | 'isPaid'
    > & {
        subscriberLists: Array<
            Pick<
                ISubscriberList,
                'id' | 'enable' | 'sourceCode' | 'polarisId' | 'externalId' | 'price'
            > & {
                offerType: Pick<IOfferType, 'name'>;
            }
        >;
        logo: Pick<IMedia, 'url' | 'provider_metadata'> | null;
    };
    cover: Pick<IMedia, 'url' | 'provider_metadata'> | null;
    seoCover: Pick<IMedia, 'url'> | null;
    audio: Pick<IMedia, 'url'> | null;
    actions?: IAction[];
}

export interface PickedComment extends Pick<IComment, 'id' | 'content'> {
    author: {
        name: string;
        avatar: string | null;
    };
}

export interface PostProps {
    post: PickedPost;
    coregPosts: PickedPost[];
    lastComments: PickedComment[];
    totalComments: number;
    schemaOrgDefinition: SchemaOrgBlogPostingDefinition;
    canonicalUrl: string;
    domain: string;
}

interface GetPostServerSidePropsHelperArgs {
    postId: string;
    canonicalUrl: string;
    publicationSlug?: IPublication['slug'];
    userToken?: string;
    domain: string;
}

export async function getPostServerSidePropsHelper({
    postId,
    canonicalUrl,
    publicationSlug,
    userToken,
    domain,
}: GetPostServerSidePropsHelperArgs): Promise<{ props: PostProps } | PageRedirect> {
    const query = {
        fields: [
            'id',
            'title',
            'subtitle',
            'text',
            'isPaid',
            'seoTitle',
            'seoSubtitle',
            'publishedAt',
            'updatedAt',
            'createdAt',
            'readingTime',
            'wordsCount',
            'hideCover',
        ],
        populate: {
            user: {
                fields: ['id', 'pseudoName', 'slug', 'biography'],
                populate: {
                    avatar: {
                        fields: ['url'],
                    },
                },
            },
            publication: {
                fields: ['id', 'title', 'description', 'thematic', 'slug', 'createdAt', 'isPaid'],
                populate: {
                    subscriberLists: {
                        fields: ['id', 'enable', 'sourceCode', 'polarisId', 'externalId', 'price'],
                        populate: {
                            offerType: {
                                fields: ['name'],
                            },
                        },
                    },
                    logo: {
                        fields: ['url', 'provider_metadata'],
                    },
                },
            },
            cover: {
                fields: ['url', 'provider_metadata'],
            },
            seoCover: {
                fields: ['url'],
            },
            audio: {
                fields: ['url'],
            },
        },
    };

    const { data: post } = await getPost<PickedPost>(parseInt(postId, 10), query, userToken);
    if (publicationSlug) {
        if (
            ![post.publication.slug.toLowerCase(), post.publication.id.toString()].includes(
                publicationSlug,
            )
        ) {
            return goToHomePage(ErrorMessages.MALFORMED_URL);
        }
    }

    const coregPostsQuery = {
        ...query,
        filters: {
            id: { $ne: post.id },
            publication: {
                id: post.publication.id,
            },
        },
        pagination: {
            page: 1,
            pageSize: 2,
        },
    };

    const { data: coregPosts } = await getPosts<PickedPost>(coregPostsQuery);

    const { data: comments, meta: commentsMeta } = await getComments(
        'post.post',
        post.id,
        commentsQuery(1, 3),
    );

    const schemaOrgDefinition = getBlogPostingSchema(post);

    return {
        props: {
            post,
            coregPosts,
            lastComments: comments,
            totalComments: commentsMeta.pagination.total,
            schemaOrgDefinition,
            canonicalUrl,
            domain,
        },
    };
}

export function isPostHasPaywall(textString: string): boolean {
    try {
        const textObject: JSONContent = JSON.parse(textString);
        if (!Array.isArray(textObject.content)) {
            return false;
        }
        return textObject.content.some((node) => node.type === PaywallName);
    } catch (error) {
        return false;
    }
}
