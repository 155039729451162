import routes from '../../../config/routes';
import type { IPost } from '../../../features/post';
import type { IPublication } from '../../../features/publication';
import type IMedia from '../../../shared/interfaces/media.interface';
import AvatarPartager from '../../basic/AvatarPartager';
import Link from '../../elements/Link';
import Paragraph from '../../elements/Paragraph';

export interface PostPreviewProps {
    post: Pick<IPost, 'id' | 'seoTitle' | 'seoSubtitle' | 'title' | 'subtitle'> & {
        seoCover: Pick<IMedia, 'url'> | null;
        cover: Pick<IMedia, 'url'> | null;
    };
    publication: Pick<IPublication, 'slug'>;
}

export default function PostPreview({ post, publication }: PostPreviewProps): JSX.Element {
    return (
        <Link
            href={routes.getPost.getUrl(publication.slug, post.id)}
            className="flex flex-col sm:flex-row max-w-full p-4 sm:p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 no-underline">
            <AvatarPartager
                src={post.seoCover?.url ?? post.cover?.url}
                name={post.seoTitle || post.title}
                size={20}
                className="mr-6"
                square
            />
            <div className="flex-auto w-40 mt-4 sm:mt-0">
                <h1 className="font-sans font-bold text-lg mb-2 text-ellipsis truncate w-full">
                    {post.seoTitle || post.title}
                </h1>
                <Paragraph variant="frontend" className="!text-base mb-6 truncate w-full">
                    {post.seoTitle || post.title}
                </Paragraph>
                <div className="text-gray-500 text-sm truncate w-full">
                    <div>{`${process.env.NEXT_PUBLIC_APP_URL}${routes.getPost.getUrl(
                        publication.slug,
                        post.id,
                    )}`}</div>
                </div>
            </div>
        </Link>
    );
}
