import routes, { actions } from '../../../../config/routes';
import type IProviderMetadata from '../../../../shared/interfaces/provider-metadata.interface';
import AvatarPartager from '../../../basic/AvatarPartager';
import GenericDropDown from '../../../basic/GenericDropDown';
import NavOption from '../../Navigation/BackendNav/NavOption';
import type { NavOptionList } from '../../Navigation/navigation.interface';

const links: NavOptionList = [
    { id: 0, ...routes.dashboard },
    { id: 1, ...routes.subscriptions },
    { id: 2, ...routes.editUser },
    { id: 3, ...actions.logout },
];

export interface AuthenticatedNavProps {
    username: string;
    email: string;
    provider_metadata?: Pick<IProviderMetadata, 'public_id'>;
}

export default function AuthenticatedNav({
    username,
    email,
    provider_metadata,
}: AuthenticatedNavProps): JSX.Element {
    return (
        <GenericDropDown
            buttonClassName="text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 rounded-full items-center dark:text-gray-100 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            buttonNode={
                <AvatarPartager
                    name={username}
                    provider_metadata={provider_metadata}
                    variant="beam"
                />
            }
            emplacement="bottom-left">
            <div className="bg-white divide-y divide-gray-100 rounded shadow w-64 dark:bg-gray-700 dark:divide-gray-600">
                <div className="px-4 py-3 text-sm text-gray-900 dark:text-white flex">
                    <div className="mr-4">
                        <AvatarPartager
                            name={username}
                            provider_metadata={provider_metadata}
                            variant="beam"
                        />
                    </div>
                    <div className="truncate">
                        <div>{username}</div>
                        <div className="text-gray-500 font-medium truncate">{email}</div>
                    </div>
                </div>
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200 flex flex-col">
                    {links.map((link) => (
                        <li key={link.id}>
                            <NavOption
                                getUrl={link.getUrl}
                                onClick={link.onClick}
                                className="flex items-center gap-2 px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                {link.icon}
                                {link.name}
                            </NavOption>
                        </li>
                    ))}
                </ul>
            </div>
        </GenericDropDown>
    );
}
