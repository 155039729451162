import routes from '../config/routes';
import type { PageRedirect } from './interfaces/page';

export function goTo(destination: string): PageRedirect {
    return {
        redirect: {
            permanent: false,
            destination,
        },
    };
}

export function goToLogin(errorMessage: string): PageRedirect {
    return goTo(routes.login.getUrl(errorMessage));
}

export function goToHomePage(errorMessage?: string): PageRedirect {
    return goTo(routes.home.getUrl(errorMessage));
}

export function goToProfile(slugOrId: string | number): PageRedirect {
    return goTo(routes.getUser.getUrl(slugOrId));
}

export function goToDashboard(): PageRedirect {
    return goTo(routes.dashboard.getUrl());
}

export function goToPage500(): PageRedirect {
    return goTo(routes.page500.getUrl());
}
