import type { JSONContent } from '@tiptap/core';
import type { IncomingMessage } from 'http';

import { isValidJSON } from '../../lib/helper/helper';

function getWordsFromPhrase(text: string): string[] {
    return text.split(' ').filter((word) => word.length > 0);
}

function getWordsFromTiptapNode(tiptapNode: JSONContent, currentWords: string[]): string[] {
    if (!tiptapNode.content) {
        return currentWords;
    }
    return tiptapNode.content.reduce<string[]>((accumulator, node) => {
        if (node.type === 'text' && node.text) {
            return [...accumulator, ...getWordsFromPhrase(node.text)];
        }
        return getWordsFromTiptapNode(node, accumulator);
    }, currentWords);
}

export function getRawTextFromTiptap(tiptapContentString: string | null): string {
    if (!tiptapContentString || !isValidJSON(tiptapContentString)) {
        return '';
    }
    const tiptapObject: JSONContent = JSON.parse(tiptapContentString);

    const words = getWordsFromTiptapNode(tiptapObject, []);

    return words.join(' ');
}

export function getFullUrl({ headers }: IncomingMessage, url: string): string {
    return `${headers['x-forwarded-proto'] || 'http'}://${headers.host}${url}`;
}
