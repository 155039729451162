import routes from '../../../../config/routes';
import type { IUser } from '../../../../features/user';
import { links } from '../../../../lib/website-static-links';
import Link from '../../../elements/Link';
import LinkButton from '../../../elements/LinkButton';

interface MiniFooterProps {
    user: Pick<IUser, 'pseudoName'>;
    isLoggedIn: boolean;
}

export default function MiniFooter({ user, isLoggedIn = false }: MiniFooterProps): JSX.Element {
    return (
        <div className="min-w-full flex flex-col lg:flex-row space-y-8 lg:space-y-0 justify-between p-8 px-3 lg:px-11 lg:py-6 bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-gray-300 text-sm">
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:items-center">
                <div className="text-gray-500">© 2023 - {user.pseudoName}</div>
                <Link href={`${links.privacy}&utm_content=footer-policies-link`}>
                    {routes.confidentiality.name}
                </Link>
                <Link href={`${links.cgu}&utm_content=footer-cgu-link`}>
                    {routes.conditions.name}
                </Link>
            </div>
            <div className="flex flex-col md:justify-between md:flex-row space-y-4 md:space-y-0 md:space-x-8 md:items-center">
                <div className="ml-0 lg:ml-20">
                    <Link href={routes.home.getUrl()} className="font-bold no-underline">
                        Partager
                    </Link>{' '}
                    – Écrire et partager avec votre communauté
                </div>

                {!isLoggedIn && (
                    <div>
                        <LinkButton href={routes.login.getUrl()} color="alternative">
                            <span className="whitespace-nowrap">Publier sur Partager</span>
                        </LinkButton>
                    </div>
                )}
            </div>
        </div>
    );
}
