import IconMail from 'bootstrap-icons/icons/envelope.svg';
import IconFacebook from 'bootstrap-icons/icons/facebook.svg';
import IconLink45 from 'bootstrap-icons/icons/link-45deg.svg';
import IconTwitter from 'bootstrap-icons/icons/twitter.svg';

import classNames from '../../../lib/classnames';
import { copyToClipboard } from '../../../lib/helper/helper';
import { emailShare, facebookShare, twitterShare } from '../../../lib/helper/social-helper';
import Button from '../../elements/Button';

interface SocialSharingProps {
    url: string;
    title: string;
    className?: string;
}

export default function SocialSharing({ url, title, className }: SocialSharingProps): JSX.Element {
    return (
        <div
            className={classNames(
                'flex flex-row justify-between w-full mt-8 p-4 dark:bg-gray-800 rounded-lg  md:py-2',
                className,
            )}>
            <Button size="no-size" onClick={() => copyToClipboard(url)} color="transparent">
                <div className="text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 rounded-full p-4 items-center dark:text-gray-100 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <IconLink45 />
                </div>
            </Button>
            <Button size="no-size" color="transparent" onClick={() => facebookShare(url, title)}>
                <div className="text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 rounded-full p-4 items-center dark:text-gray-100 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <IconFacebook />
                </div>
            </Button>
            <Button size="no-size" color="transparent" onClick={() => twitterShare(url, { title })}>
                <div className="text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 rounded-full p-4 items-center dark:text-gray-100 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <IconTwitter />
                </div>
            </Button>
            <Button
                size="no-size"
                color="transparent"
                onClick={() => emailShare(url, { subject: title })}>
                <div className="flex text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 rounded-full p-4 items-center dark:text-gray-100 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <IconMail className="block m-auto" />
                </div>
            </Button>
        </div>
    );
}
