import { useEffect, useRef } from 'react';

import type { PortalProps } from '../Portal';
import Portal from '../Portal';

export interface ModalProps extends PortalProps {
    closeModal: () => void;
}

export default function Modal({ children, isOpen, closeModal }: ModalProps): JSX.Element {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        function handleClickOutside({ target }: MouseEvent): void {
            if (target instanceof Node) {
                if (wrapperRef?.current?.isSameNode(target)) {
                    closeModal();
                }
            }
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, [wrapperRef, closeModal]);

    return (
        <Portal isOpen={isOpen}>
            <div
                className="relative z-50"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
                        ref={wrapperRef}>
                        <div className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
}
