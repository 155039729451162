import NextLink from 'next/link';
import type { ReactNode } from 'react';

import classNames from '../../../lib/classnames';
import type { ButtonColor } from '../Button';
import { buttonColor, buttonSize } from '../Button';

export interface LinkButtonProps {
    color?: ButtonColor;
    size?: 'extra-small' | 'small' | 'base' | 'large' | 'extra-large';
    href: string;
    children: ReactNode;
    className?: string;
    target?: string;
    rel?: string;
    onClick?: () => void;
}

export default function LinkButton({
    children,
    color = 'primary',
    size = 'base',
    className,
    href,
    target,
    onClick,
    rel,
}: LinkButtonProps): JSX.Element {
    return (
        <NextLink href={href}>
            <a
                href={href}
                target={target}
                onClick={onClick}
                rel={rel}
                className={classNames(
                    buttonColor[color],
                    buttonSize[size],
                    'cursor-pointer font-bold rounded-lg focus:z-10 focus:ring-2',
                    className,
                )}>
                {children}
            </a>
        </NextLink>
    );
}
