import Heading2 from '../../../../components/elements/Heading/Heading2';
import type { PostPreviewProps } from '../../../../components/modules/PostPreview';
import PostPreview from '../../../../components/modules/PostPreview';
import SocialSharing from '../../../../components/modules/SocialSharing';
import routes from '../../../../config/routes';

export interface ShareModalProps extends PostPreviewProps {}

export default function ShareModal({ post, publication }: ShareModalProps): JSX.Element {
    const postUrl = `${process.env.NEXT_PUBLIC_APP_URL}${routes.getPost.getUrl(
        publication.slug,
        post.id,
    )}`;

    return (
        <div className="m-auto p-8 dark:bg-gray-900">
            <Heading2 level={1} className="!mt-0">
                Partager l&apos;article
            </Heading2>
            <PostPreview post={post} publication={publication} />
            <SocialSharing url={postUrl} title={post.seoTitle || post.title} />
        </div>
    );
}
