import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
    isOpen: boolean;
    children: ReactNode;
}

export default function Portal({ isOpen, children }: PortalProps): JSX.Element | null {
    const ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
        ref.current = document.getElementById('portal');
    }, []);

    if (!isOpen || !ref.current) {
        return null;
    }

    return createPortal(children, ref.current);
}
