import { DateTime } from 'luxon';

export function formatDateRelative(date: string): string | null {
    return DateTime.fromISO(date)
        .setLocale('fr')
        .toRelative({
            unit: ['years', 'quarters', 'months', 'weeks', 'days', 'hours', 'minutes'],
        });
}

export function formatDateRelativeWithSeconds(date: string): string | null {
    return DateTime.fromISO(date).setLocale('fr').toRelative();
}

export function formatDateFull(date: string): string {
    return DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATE_FULL);
}

export function formatDateMed(date: string): string {
    return DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATE_MED);
}

export function formatDateTimeMed(date: string): string {
    return DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATETIME_MED);
}

export function getDateYear(date: string): string {
    return DateTime.fromISO(date).setLocale('fr').year.toString();
}

export function getDateNow(): string {
    return DateTime.now().toLocaleString(DateTime.DATE_MED);
}
