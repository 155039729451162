import IconHeartFill from 'bootstrap-icons/icons/heart-fill.svg';
import IconHeart from 'bootstrap-icons/icons/heart.svg';
import type { MouseEventHandler } from 'react';

import classNames from '../../../lib/classnames';

export interface LikeCounterProps {
    count?: number;
    liked?: boolean;
    className?: string;
    onClick?: MouseEventHandler;
}

export default function LikeCounter({
    count,
    liked = false,
    className,
    onClick,
}: LikeCounterProps): JSX.Element {
    let icon = <IconHeart className="mr-1" />;
    let color = '';

    if (liked) {
        icon = <IconHeartFill className="mr-1" />;
        color = 'text-green-800 font-bold dark:text-green-500';
    }
    return (
        <button type="button" onClick={onClick}>
            <div
                className={classNames(
                    'flex items-center text-xs dark:text-white',
                    color,
                    className,
                )}>
                {icon}
                {count || ''}
            </div>
        </button>
    );
}
