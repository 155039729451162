import routes from '../../../../config/routes';
import { isMainDomain } from '../../../../lib/helper/helper';
import type { IPost } from '../../../post';
import type { IPublication } from '../../publication.interface';

export function getRelativePath(
    domain: string,
    publicationSlug: IPublication['slug'],
    postId: IPost['id'],
): string {
    return isMainDomain(domain)
        ? routes.getPost.getUrl(publicationSlug, postId)
        : routes.getPostCustomDomain.getUrl(postId);
}
