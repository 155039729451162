import { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useCookies } from 'react-cookie';

import { createAction, deleteAction, getActions, IActionType } from '../../features/action';
import type { IAction } from '../../features/action';
import type { IPost } from '../../features/post';

async function getTotalLikes(
    postId: IPost['id'],
    setLikesCounter: Dispatch<SetStateAction<number>>,
): Promise<void> {
    try {
        const query = {
            filters: {
                post: postId,
                type: IActionType.Like,
            },
            fields: ['id'],
            populate: {
                post: {
                    fields: ['title'],
                },
            },
            pagination: {
                start: 0,
                limit: 1,
                withCount: true,
            },
        };

        const {
            meta: {
                pagination: { total },
            },
        } = await getActions(query);

        setLikesCounter(total);
    } catch (error) {
        console.error(error);
    }
}

async function getLikes(
    postId: IPost['id'],
    visitorToken: IAction['visitorToken'],
    setLikes: Dispatch<SetStateAction<PickedAction[]>>,
): Promise<void> {
    try {
        const query = {
            filters: {
                post: postId,
                type: IActionType.Like,
                visitorToken,
            },
            fields: ['visitorToken'],
            populate: {
                post: {
                    fields: ['title'],
                },
            },
        };

        const { data: likes } = await getActions(query);

        setLikes(likes);
    } catch (error) {
        console.error(error);
    }
}

async function likeAction(
    postId: IPost['id'],
    visitorToken: string,
    setLikes: Dispatch<SetStateAction<PickedAction[]>>,
    cancel: () => void,
): Promise<void> {
    try {
        const dataNewLike = {
            data: {
                post: postId,
                type: 'like',
                visitorToken,
            },
        };

        const { data: addedLike } = await createAction(dataNewLike, '');

        // add new created action to likes(actions) array manually instead of running a query to fetch new actions array
        setLikes((oldLikes) => {
            oldLikes.push(addedLike);
            return oldLikes;
        });
    } catch (error) {
        console.error(error);
        cancel();
    }
}

async function dislikeAction(
    actionId: IAction['id'],
    likeIndex: number,
    setLikes: Dispatch<SetStateAction<PickedAction[]>>,
    cancel: () => void,
): Promise<void> {
    try {
        await deleteAction(actionId);

        // delete the action manually from the likes(actions) array instead of running a query to fetch new actions array
        setLikes((oldLikes) => {
            oldLikes.splice(likeIndex, 1);
            return oldLikes;
        });
    } catch (error) {
        console.error(error);
        cancel();
    }
}

type UseLikeReturnValue = [total: number, status: boolean, onClick: () => void];

type PickedAction = Pick<IAction, 'id' | 'visitorToken'>;

interface UseLikeProps {
    postId: IPost['id'];
    likesProp?: PickedAction[];
}

export default function useLike({ postId, likesProp }: UseLikeProps): UseLikeReturnValue {
    const [likes, setLikes] = useState<PickedAction[]>(likesProp || []);
    const [likesCounter, setLikesCounter] = useState<number>(0);
    const [likeStatus, setLikeStatus] = useState<boolean>(false);

    const [cookies] = useCookies();

    useEffect(() => {
        if (likesProp) {
            setLikesCounter(likesProp.length);
            setLikes(likesProp);
        } else {
            getTotalLikes(postId, setLikesCounter);
            getLikes(postId, cookies.visitorToken, setLikes);
        }
    }, [postId, likesProp, cookies.visitorToken]);

    useEffect(() => {
        const index = likes.findIndex((like) => like.visitorToken === cookies.visitorToken);
        setLikeStatus(index > -1);
    }, [likes, cookies]);

    function onLikeClick(): void {
        const currentStatus = likeStatus;
        const currentCounter = likesCounter;

        function cancel(): void {
            setLikeStatus(currentStatus);
            setLikesCounter(currentCounter);
        }

        if (likeStatus) {
            const likeIndex = likes.findIndex((like) => like.visitorToken === cookies.visitorToken);
            if (likeIndex > -1) {
                setLikeStatus(!currentStatus);
                setLikesCounter(currentCounter - 1);
                dislikeAction(likes[likeIndex].id, likeIndex, setLikes, cancel);
            }
        } else {
            setLikeStatus(!currentStatus);
            setLikesCounter(currentCounter + 1);
            likeAction(postId, cookies.visitorToken, setLikes, cancel);
        }
    }

    return [likesCounter, likeStatus, onLikeClick];
}
