import IconReply from 'bootstrap-icons/icons/reply.svg';
import type { MouseEventHandler } from 'react';

import routes from '../../../config/routes';
import type { IOfferType } from '../../../features/offerType';
import type { IPublication } from '../../../features/publication';
import type { ISubscriberList } from '../../../features/subscriberList';
import type { IUser } from '../../../features/user';
import classNames from '../../../lib/classnames';
import { formatDateRelative } from '../../../lib/helper/date-helper';
import type IMedia from '../../../shared/interfaces/media.interface';
import CommentCounter from '../../basic/CommentCounter';
import LikeCounter from '../../basic/LikeCounter';
import Button from '../../elements/Button';
import Link from '../../elements/Link';
import SubscriberForm from '../SubscriberForm';

interface PickedSubscriberList
    extends Pick<
        ISubscriberList,
        'id' | 'enable' | 'sourceCode' | 'polarisId' | 'externalId' | 'price'
    > {
    offerType: Pick<IOfferType, 'name'>;
}

export interface SubscribeBannerProps {
    publication: Pick<
        IPublication,
        'id' | 'title' | 'description' | 'createdAt' | 'slug' | 'isPaid'
    > & {
        subscriberLists: PickedSubscriberList[];
        logo: Pick<IMedia, 'url'> | null;
    };
    user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
    className?: string;
    likeData?: { total: number; liked: boolean; onClick: MouseEventHandler };
    totalComments?: number;
    onShareClick?: MouseEventHandler;
    onCommentsClick?: () => void;
}

export default function SubscribeBanner({
    publication,
    user,
    className,
    likeData,
    totalComments,
    onShareClick,
    onCommentsClick,
}: SubscribeBannerProps): JSX.Element {
    const showSocialButtons =
        likeData !== undefined && totalComments !== undefined && onShareClick !== undefined;

    return (
        <div className={classNames('bg-gray-50 dark:bg-gray-800', className)}>
            <div className="max-w-[768px] mx-auto flex flex-wrap justify-between py-12 px-4 md:px-0">
                <div className="md:w-2/3">
                    <SubscriberForm
                        publication={publication}
                        className="md:pb-0 md:m-0 m-auto"
                        isSubscribeBanner
                    />
                </div>
                <div className="mt-8 md:mt-0">
                    <div className="flex flex-col text-gray-500 text-sm whitespace-nowrap">
                        <Link
                            href={routes.getUser.getUrl(user.slug ?? user.id)}
                            className="flex flex-row mr-4 no-underline">
                            <span>par&nbsp;</span>
                            <div className="font-bold text-gray-800 dark:text-white">
                                {user.pseudoName}
                            </div>
                        </Link>
                        <div>Lancé {formatDateRelative(publication.createdAt)}</div>
                    </div>
                    {showSocialButtons && (
                        <div className="flex flex-row mt-4 space-x-4">
                            <LikeCounter
                                count={likeData?.total}
                                liked={likeData?.liked}
                                onClick={likeData?.onClick}
                            />
                            <Button
                                type="button"
                                color="transparent"
                                size="no-size"
                                onClick={onCommentsClick}>
                                <CommentCounter count={totalComments || 0} />
                            </Button>
                            <button type="button" onClick={onShareClick}>
                                <IconReply className="-scale-x-100" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
