function objectToGetParams(object: { [key: string]: string | number | undefined | null }): string {
    const params = Object.entries(object)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

    return params.length > 0 ? `?${params.join('&')}` : '';
}

function openInTargetBlank(url: string): void {
    window.open(url, '_blank', 'noopener,noreferrer');
}

export function facebookShare(url: string, quote: string): void {
    return openInTargetBlank(
        `https://www.facebook.com/sharer/sharer.php${objectToGetParams({ u: url, quote })}`,
    );
}

export function emailShare(
    url: string,
    { subject, body, separator }: { subject: string; body?: string; separator?: string },
): void {
    openInTargetBlank(
        `mailto:${objectToGetParams({ subject, body: body ? body + separator + url : url })}`,
    );
}

export function linkedinShare(
    url: string,
    { title, summary, source }: { title?: string; summary?: string; source?: string },
): void {
    openInTargetBlank(
        `https://www.linkedin.com/sharing/share-offsite/${objectToGetParams({
            url,
            mini: 'true',
            title,
            summary,
            source,
        })}`,
    );
}

export function twitterShare(
    url: string,
    {
        title,
        via,
        hashtags = [],
        related = [],
    }: { title?: string; via?: string; hashtags?: string[]; related?: string[] },
): void {
    openInTargetBlank(
        `https://twitter.com/share${objectToGetParams({
            url,
            text: title,
            via,
            hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
            related: related.length > 0 ? related.join(',') : undefined,
        })}`,
    );
}

export function whatsappShare(
    url: string,
    { title, separator }: { title?: string; separator?: string },
): void {
    const isMobileOrTablet = /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);

    openInTargetBlank(
        `https://${isMobileOrTablet ? 'api' : 'web'}.whatsapp.com/send${objectToGetParams({
            text: title ? title + separator + url : url,
        })}`,
    );
}
