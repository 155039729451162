/* eslint-disable react/no-danger */
import Head from 'next/head';

import type { SchemaOrgBlogPostingDefinition } from '../../features/post/schema';
import type { SchemaOrgBlogDefinition } from '../../features/publication/schema';
import type { SchemaOrgProfilePageDefinition } from '../../features/user/schema';

interface SchemaOrgProps {
    canonical: string;
    definition:
        | SchemaOrgProfilePageDefinition
        | SchemaOrgBlogDefinition
        | SchemaOrgBlogPostingDefinition;
}

export default function SchemaOrg({ canonical, definition }: SchemaOrgProps): JSX.Element {
    return (
        <Head>
            <link rel="canonical" href={canonical} />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(definition) }}
            />
        </Head>
    );
}
