import type { IPost } from '../post';

export interface IAction {
    id: number;
    visitorToken: string;
    type: IActionType;
    post: IPost;
}

export enum IActionType {
    Like = 'like',
}
