import { getRawTextFromTiptap } from '../../components/SchemaOrg/SchemaOrg.service';
import routes from '../../config/routes';
import type IMedia from '../../shared/interfaces/media.interface';
import type { IPublication } from '../publication';
import type { IUser } from '../user';
import type { IPost } from './post.interface';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

/**
 * Constants
 */
const authorJobTitle = 'Auteur chez Partager';
const partagerOrganisation = {
    '@type': 'Organization',
    name: 'Partager',
    sameAs: [
        'https://www.facebook.com/Partager.io',
        'https://www.linkedin.com/company/partager-io',
    ],
};

/**
 * Definition for a publication
 * {
 *   "@context": "http://schema.org",
 *   "@type": "NewsArticle",
 *   "url": "https://www.computerenhance.com/p/turns-are-better-than-radians",
 *   "mainEntityOfPage": "https://www.computerenhance.com/p/turns-are-better-than-radians",
 *   "headline": "Turns are Better than Radians",
 *   "description": "Switching away from radians makes code simpler, faster, and more precise.",
 *   "image": [
 *     {
 *       "@type": "ImageObject",
 *       "url": "https://substackcdn.com/image/fetch/h_600,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F20e598bd-cac0-4b10-8222-b432167c9159_5616x3744.jpeg"
 *     }
 *   ],
 *   "datePublished": "2022-09-26T10:08:04+02:00",
 *   "dateModified": "2022-09-26T10:08:04+02:00",
 *   "isAccessibleForFree": true,
 *   "author": [
 *     {
 *       "@type": "Person",
 *       "name": "Casey Muratori",
 *       "url": "https://substack.com/profile/11696674-casey-muratori",
 *       "description": "Lead programmer at Molly Rocket, Inc.",
 *       "identifier": "user:11696674",
 *       "sameAs": [
 *         "https://twitter.com/cmuratori"
 *       ],
 *       "image": {
 *         "@type": "ImageObject",
 *         "contentUrl": "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F4a3d02ef-2cd1-4704-89e6-7cd174389c6b_586x622.png",
 *         "thumbnailUrl": "https://substackcdn.com/image/fetch/w_128,h_128,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F4a3d02ef-2cd1-4704-89e6-7cd174389c6b_586x622.png"
 *       }
 *     }
 *   ],
 *   "publisher": {
 *     "@type": "Organization",
 *     "name": "Computer, Enhance!",
 *     "url": "https://www.computerenhance.com",
 *     "description": "Technology, systems, interviews, commentary.",
 *     "identifier": "pub:865289",
 *     "sameAs": [
 *       "https://twitter.com/cmuratori"
 *     ]
 *   }
 * }
 */

/**
 * Definition for a post of a publication
 */
export interface SchemaOrgBlogPostingDefinition {
    '@context': string;
    '@type': string;
    headline: string;
    alternativeHeadline?: string;
    url: string;
    mainEntityOfPage: string;
    description: string;
    isAccessibleForFree: boolean;
    wordcount: string;
    datePublished?: string;
    dateModified?: string;
    dateCreated: string;
    image: object[];
    author: object[];
    articleBody: string;
    publisher: object;
}

export function getBlogPostingSchema(
    post: Pick<
        IPost,
        | 'id'
        | 'title'
        | 'subtitle'
        | 'seoTitle'
        | 'text'
        | 'isPaid'
        | 'createdAt'
        | 'publishedAt'
        | 'updatedAt'
        | 'wordsCount'
    > & {
        publication: Pick<IPublication, 'id' | 'title' | 'description' | 'slug'>;
        user: Pick<IUser, 'id' | 'slug' | 'pseudoName' | 'biography'> & {
            avatar: Pick<IMedia, 'url'> | null;
        };
        cover: Pick<IMedia, 'url' | 'provider_metadata'> | null;
        seoCover: Pick<IMedia, 'url'> | null;
    },
): SchemaOrgBlogPostingDefinition {
    const postWords = getRawTextFromTiptap(post.text);

    const schemaOrgDefinition: SchemaOrgBlogPostingDefinition = {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        url: `${APP_URL}${routes.getPost.getUrl(post.publication.slug, post.id)}`,
        mainEntityOfPage: `${APP_URL}${routes.getPost.getUrl(post.publication.slug, post.id)}`,
        headline: post.title,
        description: post.subtitle,
        isAccessibleForFree: !post.isPaid,
        wordcount: `${post.wordsCount}`,
        dateCreated: post.createdAt.split('T')[0],
        articleBody: postWords,
        image: [],
        author: [
            {
                '@type': 'Person',
                name: post.user.pseudoName,
                url: `${APP_URL}${routes.getUser.getUrl(post.user.slug ?? post.user.id)}`,
                jobTitle: authorJobTitle,
                memberOf: partagerOrganisation,
                description: getRawTextFromTiptap(post.user.biography),
                identifier: `user:${post.user.id}`,
                image: [
                    {
                        '@type': 'ImageObject',
                        url: post.user.avatar?.url || '',
                    },
                ],
            },
        ],
        publisher: {
            '@type': 'Organization',
            name: post.publication.title,
            url: routes.publication.getUrl(post.publication.slug),
            description: post.publication.description,
            identifier: `pub:${post.publication.id}`,
        },
    };

    // Add alternative headline if exists
    if (post.seoTitle !== null) {
        schemaOrgDefinition.alternativeHeadline = post.seoTitle;
    }

    if (typeof post.seoCover?.url !== 'undefined') {
        schemaOrgDefinition.image.push({
            '@type': 'ImageObject',
            url: post.seoCover?.url,
        });
    }
    if (typeof post.cover?.url !== 'undefined') {
        schemaOrgDefinition.image.push({
            '@type': 'ImageObject',
            url: post.cover?.url,
        });
    }
    if (typeof post.publishedAt !== 'undefined' && post.publishedAt !== null) {
        const [datePublished] = post.publishedAt.split('T');
        schemaOrgDefinition.datePublished = datePublished;
    }
    if (typeof post.updatedAt !== 'undefined' && post.updatedAt !== null) {
        const [dateModified] = post.updatedAt.split('T');
        schemaOrgDefinition.dateModified = dateModified;
    }

    return schemaOrgDefinition;
}
