export enum ErrorMessages {
    UNAUTHORIZED = 'Vous devez être connecté pour accèder à cette page',
    MALFORMED_URL = "Votre url n'est pas bien formée",
    INTERNAL_ERROR = 'Une erreur est survenue, veuillez ressayer plus tard',
}

export enum PublicationErrorMessages {
    NOT_FOUND = "Aucune publication n'a été trouvée",
}

export enum PostErrorMessages {
    NOT_FOUND = "Aucun article n'a été trouvé",
}
