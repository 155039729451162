import { fill } from '@cloudinary/url-gen/actions/resize';
import IconLock from 'bootstrap-icons/icons/lock-fill.svg';
import IconReply from 'bootstrap-icons/icons/reply.svg';
import React from 'react';

import routes from '../../../../config/routes';
import type { IAction } from '../../../../features/action';
import type { IPost } from '../../../../features/post';
import type { IPublication } from '../../../../features/publication';
import { getRelativePath } from '../../../../features/publication/components/PublicationPosts/PublicationPosts.service';
import type { IUser } from '../../../../features/user';
import { formatDateRelative } from '../../../../lib/helper/date-helper';
import { isPostHasPaywall } from '../../../../lib/helper/post-helper';
import useAction from '../../../../lib/hooks/likeHook';
import type IMedia from '../../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../../shared/interfaces/provider-metadata.interface';
import CommentCounter from '../../../basic/CommentCounter';
import LikeCounter from '../../../basic/LikeCounter';
import Button from '../../../elements/Button';
import Heading3 from '../../../elements/Heading/Heading3';
import Link from '../../../elements/Link';
import Paragraph from '../../../elements/Paragraph';
import ImageCloudinary from '../../../ImageCloudinary';

export interface PostTeaserProps {
    post: Pick<IPost, 'id' | 'title' | 'subtitle' | 'createdAt' | 'text'> & {
        cover:
            | (Pick<IMedia, 'url'> & {
                  provider_metadata: Pick<IProviderMetadata, 'public_id'>;
              })
            | null;
        totalComments?: number;
        actions?: IAction[];
    };
    publication: Pick<IPublication, 'slug'>;
    user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
    onClickShare?: (postId: number) => void;
    domain: string;
}

export default function PostTeaser({
    post,
    publication,
    user,
    onClickShare,
    domain,
}: PostTeaserProps): JSX.Element {
    const [totalLikes, isLiked, onLikeClick] = useAction({
        postId: post.id,
        likesProp: post.actions,
    });
    const relativePath = getRelativePath(domain, publication.slug, post.id);
    return (
        <article className="flex flex-row p-4 lg:hover:bg-gray-50 lg:dark:hover:bg-gray-700 rounded-2xl">
            {post.cover?.provider_metadata && (
                <Link href={relativePath} className="hidden md:block mr-8">
                    <ImageCloudinary
                        className="w-[168px] aspect-video"
                        provider_metadata={post.cover.provider_metadata}
                        resizeParameters={fill().width(150).height(84)}
                    />
                </Link>
            )}
            <div className="w-full md:w-[100%]">
                <Link type="alternative" href={relativePath}>
                    <Heading3 variant="frontend" level={1} className="!mt-0 !mb-2">
                        {post.title}
                    </Heading3>
                    <Paragraph variant="frontend">{post.subtitle}</Paragraph>
                </Link>
                <div className="flex flex-row text-gray-500 text-sm whitespace-nowrap mt-8">
                    <Link
                        type="alternative"
                        href={routes.getUser.getUrl(user.slug ?? user.id)}
                        className="flex flex-row space-x-2 mr-2">
                        <div className="font-bold text-gray-800 dark:text-white">
                            {user.pseudoName}
                        </div>
                    </Link>
                    {isPostHasPaywall(post.text) && (
                        <div className="mr-2">
                            <span className="md:inline-flex">
                                <IconLock className="w-[15px] h-[15px]" />
                            </span>
                        </div>
                    )}
                    <div className="mr-2">
                        <span className="hidden md:inline-flex">
                            {formatDateRelative(post.createdAt)}
                        </span>
                    </div>
                    <div className="flex flex-row space-x-2 ml-auto">
                        <LikeCounter
                            count={totalLikes}
                            liked={isLiked}
                            onClick={() => onLikeClick()}
                            className="mr-2 mt-1"
                        />
                        <CommentCounter count={post.totalComments || 0} className="font-normal" />

                        {onClickShare && (
                            <Button
                                color="link"
                                onClick={() => onClickShare(post.id)}
                                className="-scale-x-100 px-0 lg:px-0 py-0 lg:py-0">
                                <IconReply />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
}
