import { useSession } from 'next-auth/react';
import type { ReactNode } from 'react';

import type { IPublication } from '../../../../../features/publication';
import type { IUser } from '../../../../../features/user';
import type IMedia from '../../../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../../../shared/interfaces/provider-metadata.interface';
import MiniFooter from '../../../Footer/MiniFooter';
import PublicationHeader from '../../../Header/PublicationHeader';

export interface PublicationStandardLayoutProps {
    children: ReactNode;
    publication: Pick<IPublication, 'title' | 'slug'> & {
        logo:
            | (Pick<IMedia, 'url'> & {
                  provider_metadata: Pick<IProviderMetadata, 'public_id'>;
              })
            | null;
    };
    user: Pick<IUser, 'pseudoName'>;
}

export default function PublicationStandardLayout({
    publication,
    children,
    user,
}: PublicationStandardLayoutProps): JSX.Element {
    const { data: session } = useSession();

    return (
        <>
            <PublicationHeader user={session?.user} publication={publication} />
            {children}
            <MiniFooter user={user} isLoggedIn={!!session?.jwt} />
        </>
    );
}
