import { fill } from '@cloudinary/url-gen/actions/resize';

import routes from '../../../../config/routes';
import type { IPublication } from '../../../../features/publication';
import type { IUser } from '../../../../features/user';
import classNames from '../../../../lib/classnames';
import type IMedia from '../../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../../shared/interfaces/provider-metadata.interface';
import AvatarPartager from '../../../basic/AvatarPartager';
import Heading3 from '../../../elements/Heading/Heading3';
import Link from '../../../elements/Link';
import AuthenticatedNav from '../AuthenticatedNav';
import UnauthenticatedNav from '../UnauthenticatedNav';

export interface PublicationHeaderProps {
    user?: Pick<IUser, 'email' | 'username'> & {
        avatar:
            | (Pick<IMedia, 'url'> & {
                  provider_metadata: Pick<IProviderMetadata, 'public_id'>;
              })
            | null;
    };
    publication: Pick<IPublication, 'title' | 'slug'> & {
        logo:
            | (Pick<IMedia, 'url'> & {
                  provider_metadata: Pick<IProviderMetadata, 'public_id'>;
              })
            | null;
    };
    className?: string;
}

export default function PublicationHeader({
    user,
    className,
    publication,
}: PublicationHeaderProps): JSX.Element {
    return (
        <div
            className={classNames(
                'fixed top-0 left-0 right-0 p-2 px-3 bg-white border lg:px-11 lg:py-6 dark:bg-gray-800 dark:border-gray-700 z-20',
                className,
            )}>
            <div className="flex">
                <div className="flex flex-row flex-grow overflow-hidden">
                    <Link
                        href={routes.publicationSlug.getUrl(publication.slug)}
                        type="no-underline"
                        className="flex items-center">
                        <AvatarPartager
                            name={publication.title}
                            provider_metadata={publication.logo?.provider_metadata}
                            square
                            className="mr-4 min-w-[40px]"
                            resizeParameters={fill().width(160).height(160)}
                        />
                        <Heading3 className="!my-auto truncate whitespace-nowrap lg:text-lg">
                            {publication.title}
                        </Heading3>
                    </Link>
                </div>
                <div className="flex items-center justify-end">
                    {user ? (
                        <AuthenticatedNav
                            username={user.username}
                            email={user.email}
                            provider_metadata={user.avatar?.provider_metadata}
                        />
                    ) : (
                        <UnauthenticatedNav />
                    )}
                </div>
            </div>
        </div>
    );
}
