import IconPerson from 'bootstrap-icons/icons/person.svg';

import routes from '../../../../config/routes';
import type { ILink } from '../../../../config/routes.interface';
import Link from '../../../elements/Link';
import LinkButton from '../../../elements/LinkButton';

const links: ILink[] = [routes.login, routes.register];

export interface UnauthenticatedNavProps {}

export default function UnauthenticatedNav({}: UnauthenticatedNavProps): JSX.Element {
    return (
        <>
            <div className="md:hidden">
                <Link
                    href={routes.login.getUrl()}
                    className="text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 rounded-full p-2.5 items-center dark:text-gray-100 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <IconPerson />
                </Link>
                <div className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                        {links.map((link) => (
                            <li key={link.getUrl()}>
                                <a
                                    href={link.getUrl()}
                                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    {link.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="hidden md:block">
                <Link
                    className="text-sm mr-4 font-bold no-underline hover:text-gray-500"
                    href={routes.login.getUrl()}>
                    {routes.login.name}
                </Link>
                <LinkButton color="alternative" href={routes.register.getUrl()}>
                    <span>S&apos;inscrire</span>
                </LinkButton>
            </div>
        </>
    );
}
